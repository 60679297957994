
import { measurementService } from '@/services/MeasurementService';
import { ServiceError } from '@/services/util/ServiceError';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { eventService } from '@/services/EventService';
import LoadingComponent from '@/components/LoadingComponent.vue';
import DaysPickerWithMonths from '@/components/wearables/common/DaysPickerWithMonths.vue';
import ActivityChart from './ActivityChart.vue';
import AccordionScoreList from '@/components/wearables/common/AccordionScoreList.vue';
import AverageHeartRateCard from '../common/AverageHeartRateCard.vue';
import StepsCard from '@/components/wearables/activity/StepsCard.vue';
import EventsCard from '@/components/wearables/activity/EventsCard.vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import MoreInfoCard from '../common/MoreInfoCard.vue';
import { patientService } from '@/services/PatientService';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import dateFormat from '@/helpers/DateFormat.helper';
export default defineComponent({
  components: {
    LoadingComponent,
    DaysPickerWithMonths,
    ActivityChart,
    AccordionScoreList,
    AverageHeartRateCard,
    MoreInfoCard,
    StepsCard,
    HeadingComponent,
    EventsCard,
  },

  setup() {
    const route = useRoute();
    const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;
    const patient = ref();
    const isLoading = ref(false);
    const glucoseStatistics = ref();
    const heartRateStatistics = ref();
    const stepStatistics = ref();
    const distanceStatistics = ref();
    const caloriesStatistics = ref();
    const events = ref();
    const { t } = useI18n();
    const tabsMenu = ref([
      { label: t(`wearables.sleepTab.title`), to: 'sleep' },
      { label: t(`wearables.activityTab.title`), to: 'activity' },
      { label: t(`wearables.nutritionTab.title`), to: 'nutrition' },
    ]);

    const selectedStartDate = ref('');
    const selectedEndDate = ref('');

    const onDayPickerChanged = (dayStart: string, dayEnd: string) => {
      if (dayStart == selectedStartDate.value && dayEnd == selectedEndDate.value) {
        return;
      }

      selectedStartDate.value = dateFormat.userTimezoneToUTC(dayStart);
      selectedEndDate.value = dateFormat.userTimezoneToUTC(dayEnd);

      fecthData();
    };

    const handleEventClick = async (eventId: string) => {
      await router.push({
        name: 'follow-up-activity-event-detail',
        params: {
          eventId,
        },
      });
    };

    const fetchStatisticsGlucose = async () => {
      glucoseStatistics.value = undefined;
      const result = await measurementService.findGlucoseStatistics(
        patientId,
        selectedStartDate.value,
        selectedEndDate.value,
      );

      if (!(result instanceof ServiceError)) {
        glucoseStatistics.value = result;
      }
    };

    const fetchStatisticsHeartRate = async () => {
      heartRateStatistics.value = undefined;
      const result = await measurementService.findHeartRateStatistics(
        patientId,
        selectedStartDate.value,
        selectedEndDate.value,
      );

      if (!(result instanceof ServiceError)) {
        heartRateStatistics.value = result;
      }
    };

    const fetchEvents = async () => {
      events.value = undefined;
      const result = await eventService.findAll(patientId, selectedStartDate.value, selectedEndDate.value);
      if (!(result instanceof ServiceError)) {
        events.value = result;
      }
    };

    const getPatientData = async () => {
      const result = await patientService.find(patientId);
      if (!(result instanceof ServiceError)) {
        patient.value = result;
      }
    };

    const fecthData = async () => {
      isLoading.value = true;
      await getPatientData();
      await fetchStatisticsGlucose();
      await fetchStatisticsHeartRate();
      await fetchEvents();
      isLoading.value = false;
    };

    onMounted(async () => {
      await fecthData();
    });

    return {
      isLoading,
      events,
      glucoseStatistics,
      heartRateStatistics,
      stepStatistics,
      distanceStatistics,
      caloriesStatistics,
      patientId,
      patient,
      selectedStartDate,
      selectedEndDate,
      handleEventClick,
      onDayPickerChanged,
      tabsMenu,
      route,
    };
  },
});
