import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = { class: "l-row--valign days_picker" }
const _hoisted_3 = { class: "p-grid--wearables-activity-row" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "p-grid--wearables-activity-column" }
const _hoisted_6 = { class: "card" }
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "p-grid--wearables-activity-column" }
const _hoisted_10 = { class: "card" }
const _hoisted_11 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_DaysPickerWithMonths = _resolveComponent("DaysPickerWithMonths")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_ActivityChart = _resolveComponent("ActivityChart")!
  const _component_EventsCard = _resolveComponent("EventsCard")!
  const _component_StepsCard = _resolveComponent("StepsCard")!
  const _component_MoreInfoCard = _resolveComponent("MoreInfoCard")!
  const _component_AverageHeartRateCard = _resolveComponent("AverageHeartRateCard")!
  const _component_AccordionScoreList = _resolveComponent("AccordionScoreList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
      "heading-icon": "icon-heart",
      tabs: _ctx.tabsMenu
    }, null, 8, ["title", "tabs"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.patient)
          ? (_openBlock(), _createBlock(_component_DaysPickerWithMonths, {
              key: 0,
              "initial-day": _ctx.patient.creationDate,
              onOnDayPickerChanged: _ctx.onDayPickerChanged
            }, null, 8, ["initial-day", "onOnDayPickerChanged"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingComponent, {
            key: 0,
            spinnerColor: "lavender-700"
          }))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["p-grid--wearables-activity-general", { 'p-grid--wearables-activity-general--loading': _ctx.isLoading }])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_ActivityChart, {
                  chartStart: _ctx.selectedStartDate,
                  chartEnd: _ctx.selectedEndDate,
                  title: _ctx.$t('wearables.activityChart.title'),
                  events: _ctx.events,
                  "glucose-statistics": _ctx.glucoseStatistics,
                  "heart-rate-statistics": _ctx.heartRateStatistics,
                  onAnnotationEventClick: _ctx.handleEventClick
                }, null, 8, ["chartStart", "chartEnd", "title", "events", "glucose-statistics", "heart-rate-statistics", "onAnnotationEventClick"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_EventsCard, {
                  title: _ctx.$t('wearables.eventsCard.title'),
                  events: _ctx.events,
                  onOnEventClick: _ctx.handleEventClick
                }, null, 8, ["title", "events", "onOnEventClick"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_StepsCard, {
                  title: "Pasos del día",
                  "patient-id": _ctx.patientId,
                  "selected-start-date": _ctx.selectedStartDate,
                  "selected-end-date": _ctx.selectedEndDate
                }, null, 8, ["patient-id", "selected-start-date", "selected-end-date"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_MoreInfoCard)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_AverageHeartRateCard, {
                  title: _ctx.$t('wearables.averageHeartRate.averageHeartRateTitleActivity'),
                  heartRateStatistics: _ctx.heartRateStatistics
                }, null, 8, ["title", "heartRateStatistics"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_AccordionScoreList, {
                  title: _ctx.$t('wearables.activityTab.glucoseDaily'),
                  statistics: _ctx.glucoseStatistics
                }, null, 8, ["title", "statistics"])
              ])
            ])
          ], 2))
    ])
  ], 64))
}