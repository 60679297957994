import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-events-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_EventDetailCard = _resolveComponent("EventDetailCard")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t('wearables.eventsCard.title'),
      "sub-heading": true,
      divider: false
    }, null, 8, ["title"]),
    (_ctx.sportsEvents && _ctx.sportsEvents.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sportsEvents, (event) => {
            return (_openBlock(), _createBlock(_component_EventDetailCard, {
              key: event.id,
              event: event,
              "is-clickable": true,
              onOnEventClick: _ctx.onEventClick
            }, null, 8, ["event", "onOnEventClick"]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_EmptyState, {
          key: 1,
          icon: "icon-confused",
          text: _ctx.$t('wearables.eventsCard.noData'),
          textAtBottom: true
        }, null, 8, ["text"]))
  ], 64))
}