
import HeadingComponent from '@/components/HeadingComponent.vue';
import { Score, ScoreType } from '@/models/Score';
import Statistics from '@/models/Statistics';
import { measurementService } from '@/services/MeasurementService';
import { ServiceError } from '@/services/util/ServiceError';
import 'chartjs-adapter-moment';
import { computed, defineComponent, ref, watchEffect } from 'vue';

export default defineComponent({
  components: { HeadingComponent },
  emits: ['annotation-event-click'],

  props: {
    patientId: {
      type: String,
      required: true,
    },
    selectedStartDate: {
      type: String,
      required: true,
    },
    selectedEndDate: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const iconsUrlBase = ref(window.location.origin + '/assets/icon/wearables/');
    const stepStadistics = ref<Statistics>();
    const distanceStadistics = ref<Statistics>();
    const caloriesStadistics = ref<Statistics>();

    const stepScore = computed(() =>
      stepStadistics.value?.scores.find((score: Score) => score.type == ScoreType.TOTAL_VALUE),
    );
    const distanceScore = computed(() =>
      distanceStadistics.value?.scores.find((score: Score) => score.type == ScoreType.TOTAL_VALUE),
    );
    const caloriesScore = computed(() =>
      caloriesStadistics.value?.scores.find((score: Score) => score.type == ScoreType.TOTAL_VALUE),
    );

    const fechData = async () => {
      await fetchStatisticsStep();
      await fetchStatisticsDistance();
      await fetchStatisticsCalories();
    };

    const fetchStatisticsStep = async () => {
      stepStadistics.value = undefined;
      const result = await measurementService.findStepStatistics(
        props.patientId,
        props.selectedStartDate,
        props.selectedEndDate,
      );

      if (!(result instanceof ServiceError)) {
        stepStadistics.value = result;
      }
    };

    const fetchStatisticsDistance = async () => {
      distanceStadistics.value = undefined;
      const result = await measurementService.findDistanceStatistics(
        props.patientId,
        props.selectedStartDate,
        props.selectedEndDate,
      );

      if (!(result instanceof ServiceError)) {
        distanceStadistics.value = result;
      }
    };

    const fetchStatisticsCalories = async () => {
      caloriesStadistics.value = undefined;
      const result = await measurementService.findCaloriesStatistics(
        props.patientId,
        props.selectedStartDate,
        props.selectedEndDate,
      );

      if (!(result instanceof ServiceError)) {
        caloriesStadistics.value = result;
      }
    };

    watchEffect(() => {
      if (props.selectedStartDate && props.selectedEndDate) {
        fechData();
      }
    });

    return {
      iconsUrlBase,
      stepScore,
      distanceScore,
      caloriesScore,
    };
  },
});
