
import HeadingComponent from '@/components/HeadingComponent.vue';
import { EventScoreDto, EventType } from '@/models/Event';
import 'chartjs-adapter-moment';
import { defineComponent, PropType, computed } from 'vue';
import EventDetailCard from './EventDetailCard.vue';
import EmptyState from '@/components/EmptyState.vue';

export default defineComponent({
  components: { HeadingComponent, EventDetailCard, EmptyState },
  emits: ['on-event-click'],
  props: {
    events: {
      type: Object as PropType<EventScoreDto[]>,
    },
  },

  setup(props, { emit }) {
    const sportsEvents = computed(() => {
      return props.events?.filter((event) => event.type === EventType.TRAINING);
    });

    const onEventClick = (event: EventScoreDto) => {
      emit('on-event-click', event);
    };
    return { onEventClick, sportsEvents };
  },
});
